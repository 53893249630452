<template>
  <!-- Table -->
  <va-data-table
    :no-data-html="$t('entitlements.showEntitlementsLogsModal.noItems')"
    :items="state.entitlements"
    :columns="state.columns"
    class="table-scroll"
  >
    <template #header(type)>
      {{ $t("entitlements.showEntitlementsLogsModal.type") }}
    </template>

    <template #header(actionAt)>
      {{ $t("entitlements.showEntitlementsLogsModal.actionAt") }}
    </template>

    <template #header(id)>
      {{ $t("entitlements.showEntitlementsLogsModal.detail") }}
    </template>

    <template #cell(type)="{ source: type }">
      <span ref="table">
        <va-badge :text="type.label" :color="type.color" />
      </span>
    </template>
    <template
      #cell(initiatedBy)="{ source: initiatedBy }"
      v-if="initiatedBy !== null"
    >
      <div class="col-icon">
        <span>
          {{ initiatedBy.type }}
        </span>
        <button-clipboard-copy
          v-if="initiatedBy.id"
          :field="$t('entitlements.userId')"
          :value="initiatedBy.id"
        />
      </div>
    </template>
    <template #cell(actionAt)="{ source: actionAt }">
      <span>
        {{ actionAt }}
      </span>
    </template>
    <template #cell(reason)="{ source: reason }">
      <span>
        {{ reason }}
      </span>
    </template>
    <template #cell(id)="{ source: id }">
      <va-button
        flat
        size="small"
        icon="arrow_forward"
        @click="goToEventDetail(id)"
      />
    </template>

    <template #bodyAppend>
      <tr>
        <td colspan="6">
          <va-inner-loading class="mt-3" :loading="state.loading" />
          <p v-if="state.allLogsFetched" class="text-center">
            {{ $t("entitlements.showEntitlementsLogsModal.allItemsFetched") }}
          </p>
        </td>
      </tr>
    </template>
  </va-data-table>
  <show-entitlement-logs-details-modal
    :is-open="state.showDetailsModal"
    :event="state.currentEvent"
    @cancel="state.showDetailsModal = false"
    @validate="validate()"
  />
</template>

<script setup>
import { computed, reactive, watch, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  callFetchEntitlementLogs,
  callFetchEntitlementLog
} from "@/api/auditing";
import { printDate } from "@/utils";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { ENTITLEMENTS_LOGS_TYPE_COLORS } from "@/constants";
import ShowEntitlementLogsDetailsModal from "../modal/ShowEntitlementLogsDetailsModal.vue";

const handleError = inject("handleError");
const route = useRoute();
const { t } = useI18n();
let table = null;

const state = reactive({
  entitlements: [],
  columns: computed(() => {
    return [{ key: "type" }, { key: "actionAt" }, { key: "id" }];
  }),
  loading: false,
  currentPage: 1,
  perPage: 10,
  totalPages: 0,
  allLogsFetched: false,
  showDetailsModal: false,
  currentEvent: null
});

/**
 * Load entitlement events from API.
 * @param {number} page - page number
 */
async function loadEntitlementEvents(page = 1) {
  state.loading = true;

  const params = {
    count: state.perPage,
    page,
    entitlementId: route.params.id
  };

  if (!state.allLogsFetched) {
    try {
      const resp = await callFetchEntitlementLogs({ params });
      console.log("tset", resp);
      if (resp?.data) {
        const newData = resp.data.items.map(item => {
          return {
            id: item?.id,
            type: {
              label: item?.type,
              color: ENTITLEMENTS_LOGS_TYPE_COLORS[item?.type]
            },
            actionAt: printDate(item?.triggeredAt),
            initiatedBy: {
              id: item?.initiatedBy?.id,
              type: item?.initiatedBy?.type ?? t("general.unknown")
            }
          };
        });

        if (newData.length === 0) {
          state.allLogsFetched = true;
        }
        state.entitlements = state.entitlements.concat(newData);
      }
    } catch (e) {
      handleError(e?.response?.data);
    }
  }

  state.loading = false;
}

/**
 * Get user id from account id.
 * @param {string} accountId - account id
 * @return {string} - user id
 */
async function fetchEvent(eventId) {
  try {
    const resp = await callFetchEntitlementLog(eventId);
    return resp.data;
  } catch (e) {
    handleError(e);
  }
}

async function goToEventDetail(eventId) {
  state.currentEvent = await fetchEvent(eventId);
  state.showDetailsModal = true;
}

const validate = () => {
  state.showDetailsModal = false;
};

const detectScrolledBottom = () => {
  if (table.scrollTop + table.clientHeight >= table.scrollHeight) {
    state.currentPage++;
  }
};

watch(
  () => state.currentPage,
  async currentPage => {
    await loadEntitlementEvents(currentPage);
  }
);

onMounted(async () => {
  table = document.querySelector(".table-scroll");
  table.addEventListener("scroll", detectScrolledBottom);
  await loadEntitlementEvents();
});
</script>

<style lang="scss" scoped>
.table-scroll {
  max-height: 25rem;
  display: block;
  overflow: overlay;
  padding-right: 1rem;
}

.col-icon {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}
</style>
