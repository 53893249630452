<template>
  <va-modal
    v-model="state.isOpenSecondModal"
    hide-default-actions
    :title="$t('entitlements.showEntitlementLogsDetailModal.title')"
  >
    <slot></slot>
    <div class="mb-3">
      <h4 class="va-h4">
        {{ $t("entitlements.showEntitlementLogsDetailModal.details") }}
      </h4>
      <table class="kv-table">
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.entitlementId")
            }}</label>
          </td>
          <td>{{ event.data.entitlementId || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.productId")
            }}</label>
          </td>
          <td>{{ event.data.productId || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.licenseId")
            }}</label>
          </td>
          <td>{{ event.data.licenseId || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.triggeredAt")
            }}</label>
          </td>
          <td>{{ formatDate(event.triggeredAt) }}</td>
        </tr>
      </table>

      <h4 class="va-h4">
        {{ $t("entitlements.showEntitlementLogsDetailModal.state") }}
      </h4>
      <table class="kv-table">
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.ownerId")
            }}</label>
          </td>
          <td>{{ event.data.state.ownerId || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.orderId")
            }}</label>
          </td>
          <td>{{ event.data.state.orderId || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.subscriptionId")
            }}</label>
          </td>
          <td>{{ event.data.state.subscriptionId || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.origin")
            }}</label>
          </td>
          <td>{{ event.data.state.origin || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.startsAt")
            }}</label>
          </td>
          <td>{{ formatDate(event.data.state.startsAt) }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.expiresAt")
            }}</label>
          </td>
          <td>{{ formatDate(event.data.state.expiresAt) }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t(
                "entitlements.showEntitlementLogsDetailModal.maintenanceExpiresAt"
              )
            }}</label>
          </td>
          <td>{{ formatDate(event.data.state.maintenanceExpiresAt) }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.licenseKey")
            }}</label>
          </td>
          <td>{{ event.data.state.licenseKey || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.licenseType")
            }}</label>
          </td>
          <td>{{ event.data.state.licenseType || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.seats")
            }}</label>
          </td>
          <td>{{ event.data.state.seats || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.isTrial")
            }}</label>
          </td>
          <td>{{ event.data.state.isTrial || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.suspended")
            }}</label>
          </td>
          <td>{{ event.data.state.suspended || "N/A" }}</td>
        </tr>
        <tr>
          <td>
            <label>{{
              $t("entitlements.showEntitlementLogsDetailModal.revoked")
            }}</label>
          </td>
          <td>{{ event.data.state.revoked || "N/A" }}</td>
        </tr>
      </table>

      <template v-if="event.data.state.attributes">
        <h4 class="va-h4">
          {{ $t("entitlements.showEntitlementLogsDetailModal.attributes") }}
        </h4>
        <table class="kv-table">
          <tr>
            <td>
              <label>{{
                $t(
                  "entitlements.showEntitlementLogsDetailModal.deactivationLimit"
                )
              }}</label>
            </td>
            <td>{{ event.data.state.attributes.DeactivationLimit }}</td>
          </tr>
        </table>
      </template>
      <template v-if="event.data.changes">
        <h4 class="va-h4">
          {{ $t("entitlements.showEntitlementLogsDetailModal.changes") }}
        </h4>
        <json-viewer :value="event.data.changes" :is-loading="false" />
      </template>
    </div>
    <template #footer>
      <va-button class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.ok") }}
      </va-button>
    </template>
  </va-modal>
</template>

<script setup>
import { computed, reactive } from "vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import JsonViewer from "../JsonViewer.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  event: {
    type: Object,
    required: true
  }
});

const state = reactive({
  isOpenSecondModal: computed(() => props.isOpen),
  event: computed(() => props.event)
});

const formatDate = date => {
  if (!date) {
    return "";
  }
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

const userLink = userId => {
  return `/admin/identity/users/${userId}`;
};

const emit = defineEmits(["cancel"]);
</script>

<style scoped>
.kv-table label:after {
  content: ":";
}

.kv-table td {
  padding: 0.5rem;
}

.kv-table label {
  font-size: 0.9em;
  font-weight: 700;
  color: var(--va-gray);
  text-transform: uppercase;
}
</style>
