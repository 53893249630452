<template>
  <base-tile
    :title="$t('entitlements.info')"
    :editMode="props.editMode"
    i18nPrefix="entitlements"
    :rows="state.rows"
  >
    <template #title>
      <div class="entitlements-header-card">
        {{ $t("entitlements.title") }}
        <va-button flat class="upgrade-button" @click="openLogs()">
          {{ $t("buttons.showLogs") }}
        </va-button>
      </div>
      <show-entitlement-logs-modal
        id="eeee"
        :isOpen="state.showLogsIsOpen"
        @cancel="state.showLogsIsOpen = false"
        @validate="validate()"
      />
    </template>

    <!-- ENTITLEMENT ID -->
    <template #[state.readEntitlementIdSlot]>
      <td v-if="state.id" class="p-2">
        <span class="identifier">{{ state.id }} </span>
        <button-clipboard-copy
          :field="$t('entitlements.id')"
          :value="state.id"
        />
      </td>
    </template>

    <!-- ORIGIN -->
    <template #[state.editOriginSlot]>
      <td class="p-2">
        <va-select
          v-model="state.origin"
          :options="ENTITLEMENTS_ORIGIN"
          @update:model-value="
            updateEntitlementEditField('origin', state.origin)
          "
        />
      </td>
    </template>

    <!-- SUBSCRIPTION ID -->
    <template #[state.readSubscriptionIdSlot]>
      <td v-if="state.subscriptionId" class="p-2">
        <span class="identifier">{{ state.subscriptionId }} </span>
        <button-clipboard-copy
          :field="$t('entitlements.subscriptionId')"
          :value="state.subscriptionId"
        />
      </td>
    </template>
    <template #[state.editSubscriptionIdSlot]>
      <td class="p-2">
        <va-input
          v-model="state.subscriptionId"
          @change="
            updateEntitlementEditField('subscriptionId', state.subscriptionId)
          "
        />
      </td>
    </template>

    <!-- EXPIRES AT -->
    <template #[state.editExpiresAtSlot]>
      <td class="p-2 td-centered">
        <va-date-input
          v-model="state.expiresAt"
          @update:model-value="
            updateEntitlementEditDate('expiresAt', state.expiresAt)
          "
        />
        <button-cancel
          :disabled="!state.expiresAt"
          @click="updateDate('expiresAt')"
        />
      </td>
    </template>

    <!-- MAINTENANCE ENDS AT -->
    <template #[state.editMaintenanceEndsAtSlot]>
      <td class="p-2 td-centered">
        <va-date-input
          v-model="state.maintenanceEndsAt"
          @update:model-value="
            updateEntitlementEditDate(
              'maintenanceEndsAt',
              state.maintenanceEndsAt
            )
          "
        />
        <button-cancel
          :disabled="!state.maintenanceEndsAt"
          @click="updateDate('maintenanceEndsAt')"
        />
      </td>
    </template>

    <!-- IS TRIAL -->
    <template #[state.editIsTrialSlot]>
      <td class="p-2">
        <va-select
          v-model="state.isTrial"
          :options="[$t('general.yes'), $t('general.no')]"
          @update:model-value="
            updateEntitlementEditField(
              'isTrial',
              state.isTrial === $t('general.yes')
            )
          "
        />
      </td>
    </template>

    <!-- SUSPENDED -->
    <template #[state.editSuspendedSlot]>
      <td class="p-2">
        <va-select
          v-model="state.suspended"
          :options="[$t('general.yes'), $t('general.no')]"
          @update:model-value="
            updateEntitlementEditField(
              'suspended',
              state.suspended === $t('general.yes')
            )
          "
        />
      </td>
    </template>

    <!-- REVOKED -->
    <template #[state.editRevokedSlot]>
      <td class="p-2">
        <va-select
          v-model="state.revoked"
          :options="[$t('general.yes'), $t('general.no')]"
          @update:model-value="
            updateEntitlementEditField(
              'revoked',
              state.revoked === $t('general.yes')
            )
          "
        />
      </td>
    </template>

    <!-- NOTES -->
    <template #[state.editNotesSlot]>
      <td class="p-2">
        <va-input
          type="textarea"
          v-model="state.notes"
          @change="updateEntitlementEditField('notes', state.notes)"
          rows="3"
        />
      </td>
    </template>
  </base-tile>
</template>

<style scoped>
.identifier {
  font-family: monospace;
  font-size: 0.9rem;
}
</style>

<script setup>
import { DateTime } from "luxon";
import { computed, reactive, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import BaseTile from "@/components/BaseTile.vue";
import ButtonCancel from "@/components/ButtonCancel.vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { useI18nValue } from "@/composable";
import { ENTITLEMENTS_ORIGIN } from "@/constants";
import { printDate } from "@/utils";
import { getEntitlementValidityPeriod } from "@/utils/licensing";
import ShowEntitlementLogsModal from "../modal/ShowEntitlementLogsModal.vue";
import { VaInput } from "vuestic-ui";

const { t } = useI18n();
const store = useStore();

const props = defineProps({
  editMode: {
    type: Boolean,
    required: true
  }
});

// state dates are Date js objects, store dates are string($date-time)
const state = reactive({
  id: store.state.licensing.entitlementEdit.id,
  showLogsIsOpen: false,
  product: null,

  origin: store.state.licensing.entitlementEdit.origin,

  subscriptionId: store.state.licensing.entitlementEdit.subscriptionId,

  expiresAt:
    store.state.licensing.entitlementEdit.expiresAt &&
    new Date(store.state.licensing.entitlementEdit.expiresAt),

  maintenanceEndsAt:
    store.state.licensing.entitlementEdit.maintenanceEndsAt &&
    new Date(store.state.licensing.entitlementEdit.maintenanceEndsAt),

  isTrial:
    store.state.licensing.entitlementEdit.isTrial === true
      ? t("general.yes")
      : t("general.no"),

  suspended:
    store.state.licensing.entitlementEdit.suspended === true
      ? t("general.yes")
      : t("general.no"),

  revoked:
    store.state.licensing.entitlementEdit.revoked === true
      ? t("general.yes")
      : t("general.no"),
  notes: store.state.licensing.entitlementEdit.notes,
  rows: computed(() => {
    const entitlementEdit = store.state.licensing.entitlementEdit;

    const isTrial =
      entitlementEdit.isTrial === true ? t("general.yes") : t("general.no");

    const suspended =
      entitlementEdit.suspended === true ? t("general.yes") : t("general.no");

    const revoked =
      entitlementEdit.revoked === true ? t("general.yes") : t("general.no");

    return [
      {
        label: t("entitlements.id"),
        value: entitlementEdit.id
      },
      {
        label: t("entitlements.product"),
        value: state.product?.name ?? entitlementEdit.productId
      },
      {
        label: t("entitlements.templateId"),
        value: entitlementEdit.templateId
      },
      {
        label: t("entitlements.origin"),
        value: useI18nValue(entitlementEdit.origin, "entitlements.originEnums"),
        editable: true
      },
      {
        label: t("entitlements.subscriptionId"),
        value: entitlementEdit.subscriptionId,
        editable: true
      },
      {
        label: t("entitlements.createdAt"),
        value: printDate(entitlementEdit.createdAt)
      },
      {
        label: t("entitlements.startsAt"),
        value: printDate(entitlementEdit.startsAt),
        editable: true
      },
      {
        label: t("entitlements.expiresAt"),
        value: getEntitlementValidityPeriod(entitlementEdit.expiresAt, t),
        editable: entitlementEdit.expiresAt
      },
      {
        label: t("entitlements.maintenanceEndsAt"),
        value: printDate(entitlementEdit.maintenanceEndsAt),
        editable: true
      },
      {
        label: t("entitlements.lastModifiedAt"),
        value: printDate(entitlementEdit.lastModifiedAt)
      },
      {
        label: t("entitlements.isTrial"),
        value: useI18nValue(isTrial, "general"),
        editable: entitlementEdit.origin !== "AutomatedTrialRequest"
      },
      {
        label: t("entitlements.suspended"),
        value: useI18nValue(suspended, "general"),
        editable: true
      },
      {
        label: t("entitlements.revoked"),
        value: useI18nValue(revoked, "general"),
        editable: true
      },
      {
        label: t("entitlements.notes"),
        value: entitlementEdit.notes,
        editable: true
      }
    ];
  }),
  readEntitlementIdSlot: `${t("entitlements.id")}-value`,
  readSubscriptionIdSlot: `${t("entitlements.subscriptionId")}-value`,
  editOriginSlot: `${t("entitlements.origin")}-edit-value`,
  editSubscriptionIdSlot: `${t("entitlements.subscriptionId")}-edit-value`,
  editExpiresAtSlot: `${t("entitlements.expiresAt")}-edit-value`,
  editMaintenanceEndsAtSlot: `${t(
    "entitlements.maintenanceEndsAt"
  )}-edit-value`,
  editIsTrialSlot: `${t("entitlements.isTrial")}-edit-value`,
  editSuspendedSlot: `${t("entitlements.suspended")}-edit-value`,
  editRevokedSlot: `${t("entitlements.revoked")}-edit-value`,
  editNotesSlot: `${t("entitlements.notes")}-edit-value`
});

const openLogs = () => {
  state.showLogsIsOpen = true;
};

const validate = () => {
  state.showLogsIsOpen = false;
};

watch(
  () => props.editMode,
  (editMode, prevEditMode) => {
    // Reset state based on the store value when we cancel or confirm the edition
    if (prevEditMode === true && editMode === false) {
      state.id = store.state.licensing.entitlementEdit.id;
      state.origin = store.state.licensing.entitlementEdit.origin;
      state.subscriptionId =
        store.state.licensing.entitlementEdit.subscriptionId;
      state.expiresAt =
        store.state.licensing.entitlementEdit.expiresAt &&
        new Date(store.state.licensing.entitlementEdit.expiresAt);
      state.maintenanceEndsAt =
        store.state.licensing.entitlementEdit.maintenanceEndsAt &&
        new Date(store.state.licensing.entitlementEdit.maintenanceEndsAt);
      state.isTrial =
        store.state.licensing.entitlementEdit.isTrial === true
          ? t("general.yes")
          : t("general.no");
      state.suspended =
        store.state.licensing.entitlementEdit.suspended === true
          ? t("general.yes")
          : t("general.no");
      state.revoked =
        store.state.licensing.entitlementEdit.revoked === true
          ? t("general.yes")
          : t("general.no");
      state.notes = store.state.licensing.entitlementEdit.notes;
    }
  }
);

/**
 * Update state and store
 * @param {string} prop - name of the entitlement prop to update
 */
const updateDate = prop => {
  state[prop] = null;
  updateEntitlementEditDate(prop, null);
};

/**
 * Modify the date into a string in the format "yyyy-MM-dd'T'HH:mm:ss'Z'".
 * Then dispatch an action to update entitlementEdit
 * @param {string} key
 * @param {Date} date
 */
const updateEntitlementEditDate = (key, date) => {
  if (!date) {
    store.dispatch("licensing/updateEntitlementEditField", {
      key,
      value: null
    });
    return;
  }

  date = removeTimezoneOffset(date);

  // convert the date into a timestamp to create a datetime object with fromMillis()
  const timestamp = +date;
  const datetime = DateTime.fromMillis(timestamp);

  // remove offset to have the time equalsto 00:00:00 of the selected day
  const dateTimeOffsetFixed = datetime.minus({ hours: datetime.offset / 60 });

  const value = dateTimeOffsetFixed.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
  store.dispatch("licensing/updateEntitlementEditField", { key, value });
};

/**
 * Removes the timezone offset from a date without modifying the time.
 * @param {Date} date - The date to remove the timezone offset from.
 * @returns {Date} A new Date object with the same time as the original date, but in UTC.
 */
function removeTimezoneOffset(date) {
  // Get the year, month, and day of the original date.
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  // Create a new Date object with the same time as the original date, but in UTC.
  // This is done by passing the year, month, and day in UTC, as well as the hours, minutes, seconds, and milliseconds of the original date, to the Date.UTC method.
  return new Date(
    Date.UTC(
      year,
      month,
      day,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

const updateEntitlementEditField = (key, value) => {
  store.dispatch("licensing/updateEntitlementEditField", { key, value });
};

async function loadProduct() {
  const productId = store.state.licensing.entitlementEdit.productId;
  if (productId) {
    state.product = await store.dispatch("licensing/getProduct", productId); // Pass the productId directly
  }
}

onMounted(async () => {
  await loadProduct();
});
</script>

<style scoped>
.td-centered {
  display: flex;
  align-items: center;
}

.entitlements-header-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
