<template>
  <va-modal
    v-model="state.isOpen"
    hide-default-actions
    :title="$t('entitlements.showEntitlementsLogsModal.title')"
    size="large"
  >
    <slot>
      <span class="d-flex mb-2">{{
        $t("entitlements.showEntitlementsLogsModal.explanation")
      }}</span>
    </slot>
    <entitlement-logs-table />
    <template #footer>
      <va-button class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.ok") }}
      </va-button>
    </template>
  </va-modal>
</template>
<script setup>
import { computed, reactive } from "vue";
import EntitlementLogsTable from "../entitlements/EntitlementLogsTable.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
});

const state = reactive({
  isOpen: computed(() => props.isOpen)
});

const emit = defineEmits(["cancel"]);
</script>
