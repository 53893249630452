import http from "@/api/http";

/**
 * Fetch activations events
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchActivationsEvents = async payload => {
  return await http.get("/api/audithub/activations", payload);
};

/**
 * Fetch activations events detail
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchActivationEvent = async eventId => {
  return await http.get(`/api/audithub/activations/${eventId}`);
};

/**
 * Fetch entitlement logs
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchEntitlementLogs = async payload => {
  return await http.get("/api/audithub/entitlements", payload);
};

/**
 * Fetch entitlement logs detail
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchEntitlementLog = async eventId => {
  return await http.get(`/api/audithub/entitlements/${eventId}`);
};
